import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import auth from '@/store/auth'

// import { Role } from '../_helpers/roles'

// eslint-disable-next-line import/no-cycle
import useRole from '@/plugins/useRole';

import { i18n } from '@/plugins/i18n'
import store from '@/store'

const { canAccess } = useRole()
Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/authentication/login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/pages/profile/index.vue'),
    meta: {
      layout: 'content',
    },
  },

  /// Begin User Routes
  {
    path: '/users',
    name: 'users.index',
    component: () => import('@/views/pages/users/index.vue'),
    meta: {
      layout: 'content',
      permission: 'users.index',

    },
  },

  /// Begin Roles Routes
  {
    path: '/roles',
    name: 'roles.index',
    component: () => import('@/views/pages/roles-permissions/roles/index.vue'),
    meta: {
      layout: 'content',
      permission: 'roles.index',
    },
  },

  /// Begin Permission Routes
  {
    path: '/permissions',
    name: 'permissions.index',
    component: () => import('@/views/pages/roles-permissions/permissions/index.vue'),
    meta: {
      layout: 'content',
      permission: 'permission.index',
    },
  },

  /// Begin Stage Routes
  {
    path: '/stage',
    name: 'stage.index',
    component: () => import('@/views/pages/stage/index.vue'),
    meta: {
      layout: 'content',
      permission: 'stage.index',

    },
  },

  /// Begin Subject Routes
  {
    path: '/subject',
    name: 'subject.index',
    component: () => import('@/views/pages/subject/index.vue'),
    meta: {
      layout: 'content',
      permission: 'subject.index',

    },
  },

  /// Begin Group Routes
  {
    path: '/group',
    name: 'group.index',
    component: () => import('@/views/pages/group/index.vue'),
    meta: {
      layout: 'content',
      permission: 'group.index',

    },
  },

  /// Begin Student Routes
  {
    path: '/student',
    name: 'student.index',
    component: () => import('@/views/pages/student/index.vue'),
    meta: {
      layout: 'content',
      permission: 'student.index',

    },
  },

  /// Begin Day Routes
  {
    path: '/day',
    name: 'day.index',
    component: () => import('@/views/pages/day/index.vue'),
    meta: {
      layout: 'content',
      permission: 'day.index',

    },
  },

  /// Begin Exam Routes
  {
    path: '/exam',
    name: 'exam.index',
    component: () => import('@/views/pages/exam/index.vue'),
    meta: {
      layout: 'content',
      permission: 'exam.index',
    },
  },

  /// Begin Timetable Routes
  {
    path: '/timetable',
    name: 'timetable.index',
    component: () => import('@/views/pages/timetable/index.vue'),
    meta: {
      layout: 'content',
      permission: 'timetable.index',
    },
  },

  /// Begin Teacher Routes
  {
    path: '/teacher',
    name: 'teacher.index',
    component: () => import('@/views/pages/teacher/index.vue'),
    meta: {
      layout: 'content',
      permission: 'teacher.index',
    },
  },

  /// Begin Post Routes
  {
    path: '/post',
    name: 'post.index',
    component: () => import('@/views/pages/post/index.vue'),
    meta: {
      layout: 'content',
      permission: 'post.index',
    },
  },

  /// Begin Site Settings Routes
  {
    path: '/settings',
    name: 'site.settings',
    component: () => import('@/views/pages/settings/index.vue'),
    meta: {
      layout: 'content',
      permission: 'users.index',
    },
  },

  {
    path: '*',
    name: 'error-404',
    component: () => import('@/views/pages/errors/404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/403',
    name: 'error-403',
    component: () => import('@/views/pages/errors/403.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
})
router.beforeEach(async (to, from, next) => {
  if (to.name !== from.name) {
    store.state.pageTitle = null;
  }
  if (to.path === '/') {
    return next({ name: 'home' });
  }

  const { isAuthenticated } = auth.getters;
  if (isAuthenticated) {
    // await statistics.dispatch('fetchApplicationStatistics');
  }
  const canAccessRoute = canAccess(to.name)
  if (!auth.state.loaded && isAuthenticated) {
    await auth.dispatch('getUser');
  }

  if (!canAccessRoute) {
    return next({ name: '403' })
  }
  if ((isAuthenticated && to.name === 'login')) {
    return next({ name: 'error-403' })
  }

  if (!isAuthenticated && to.name !== 'login') {
    return next({ name: 'login' })
  }

  return next()
})

const currentRouteName = to => {
  if (to.meta.name) {
    switch (typeof to.meta.name) {
      case 'string':
        return to.meta.name;
      case 'function':
        return to.meta.name(to)
      default:
        return '';
    }
  }

  return to.name;
};
router.afterEach(to => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = ` لوحة التحكم - ${i18n.t(`router.${currentRouteName(to)}`).toString()}`
  });
});
export default router
