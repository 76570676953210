import {
  mdiAccountGroupOutline,
  mdiHomeOutline,
  mdiSelectGroup,
  mdiAccountCheckOutline,
  mdiAccountSchoolOutline, mdiCheckAll, mdiTimetable, mdiAlert,
} from '@mdi/js'

export default [
  {
    subheader: 'menu.home1',
  },
  {
    title: 'menu.home',
    icon: mdiHomeOutline,
    color: 'info',
    to: 'home',
  },
  {
    subheader: 'menu.navigation',
  },

  {
    title: 'menu.day',
    icon: mdiAccountCheckOutline,
    color: 'success',
    to: 'day.index',
  },
  {
    title: 'menu.post',
    icon: mdiAlert,
    color: 'info',
    to: 'post.index',
  },

  {
    title: 'menu.exam',
    icon: mdiCheckAll,
    color: 'warning',
    to: 'exam.index',
  },

  {
    title: 'router.timetable.index',
    icon: mdiTimetable,
    color: 'info',
    to: 'timetable.index',
  },

  {
    title: 'menu.stage',
    icon: mdiSelectGroup,
    color: 'info',
    to: 'stage.index',
  },
  {
    title: 'menu.subject',
    icon: mdiSelectGroup,
    color: 'primary',
    to: 'subject.index',
  },

  {
    title: 'menu.student',
    icon: mdiAccountSchoolOutline,
    color: 'info',
    to: 'student.index',
  },

  {
    title: 'menu.group',
    icon: mdiAccountGroupOutline,
    color: 'info',
    to: 'group.index',
  },
  {
    title: 'menu.teacher',
    icon: mdiAccountSchoolOutline,
    color: 'warning',
    to: 'teacher.index',
  },

  {
    title: 'menu.users',
    icon: mdiAccountGroupOutline,
    color: 'error',
    children: [
      {
        title: 'menu.view_users',
        to: 'users.index',
        color: 'error',
      },
      {
        title: 'menu.roles',
        to: 'roles.index',
        color: 'error',
      },

      {
        title: 'menu.permissions',
        to: 'permissions.index',
        color: 'error',
      },
    ],
  },

  // {
  //   title: 'menu.site.settings',
  //   to: 'site.settings',
  //   color: 'error',
  //   class: 'spin',
  //   icon: mdiCog,
  // },

]
